import React, { useState } from "react";
import { motion } from "framer-motion";
import { NavLink } from "react-router-dom";

function Navbar() {
  const container = {
    show: {
      transition: {
        staggerChildren: 0.1,
      },
    },
  };
  const bottomtotop = {
    hidden: { y: -30, opacity: 0 },
    show: {
      y: 0,
      opacity: 1,
      transition: {
        ease: [0.6, 0.99, 0.99, 0.95],
        duration: 0.5,
      },
    },
  };

  const [Click, setClick] = useState(false);

  const handelClick = () => setClick(!Click);

  const [visible, setVisible] = useState(false);

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 100) {
      setVisible(true);
    } else if (scrolled <= 100) {
      setVisible(false);
    }
  };

  window.addEventListener("scroll", toggleVisible);
  /* console.log(visible); */
  return (
    <motion.div variants={container} initial="hidden" animate="show">
      <motion.div className="main_wrapper_nev" variants={bottomtotop}>
        <div
          className="overlaysistam"
          style={{
            opacity: visible ? "1" : "0",
          }}
        ></div>
        <div className="sm_logo_container">
          <NavLink to="/">
            <img
              src="/accets/images/recap_logo.svg"
              className="recaplogo"
              alt=""
            />
          </NavLink>
        </div>
        <div></div>

        <div className="hamburdermenu">
          <div className="quick-NavLinks">
            <ul>
              <li>
                {" "}
                <NavLink
                  to="/"
                  className={({ isActive }) => (isActive ? "active" : "")}
                >
                  <p>home</p>
                </NavLink>
              </li>
              <li>
                {" "}
                <NavLink
                  to="/Services"
                  className={({ isActive }) => (isActive ? "active" : "")}
                >
                  <p>services</p>
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/work"
                  className={({ isActive }) => (isActive ? "active" : "")}
                >
                  <p>Our Work</p>
                </NavLink>
              </li>
            </ul>
          </div>
          <div className="hamburdermenu-inner" onClick={handelClick}>
            <i className={Click ? "fa  fa-times" : "fa  fa-bars"} />
          </div>
        </div>
      </motion.div>
      <div
        className={
          Click ? "manulist-onopen-block show" : "manulist-onopen-block"
        }
      >
        <div className="manulist-onopen-block-inner">
          <div className="manulist-onopen-block-box linkshowcase">
            <ul className="manulist_navlist">
              <li className="manulist_nevlinks">
                <NavLink
                  to="/"
                  onClick={handelClick}
                  className={({ isActive }) => (isActive ? "active" : "")}
                >
                  <p>home</p>
                </NavLink>
              </li>
              <li className="manulist_nevlinks">
                <NavLink
                  to="/AboutUS"
                  onClick={handelClick}
                  className={({ isActive }) => (isActive ? "active" : "")}
                >
                  <p>about</p>
                </NavLink>
              </li>
              <li className="manulist_nevlinks pcversion">
                <NavLink
                  onClick={handelClick}
                  to="/Services"
                  className={({ isActive }) => (isActive ? "active" : "")}
                >
                  <p>services</p>
                </NavLink>
              </li>
              <li className="manulist_nevlinks pcversion">
                <NavLink
                  to="/work"
                  onClick={handelClick}
                  className={({ isActive }) => (isActive ? "active" : "")}
                >
                  <p>Our Work</p>
                </NavLink>
              </li>
              <li className="manulist_nevlinks pcversion">
                <NavLink
                  to="/Career"
                  onClick={handelClick}
                  className={({ isActive }) => (isActive ? "active" : "")}
                >
                  <p>Career</p>
                </NavLink>
              </li>
              <li className="manulist_nevlinks pcversion">
                <NavLink
                  to="/ContactUs"
                  onClick={handelClick}
                  className={({ isActive }) => (isActive ? "active" : "")}
                >
                  <p>Contact Us</p>
                </NavLink>
              </li>
            </ul>
          </div>
          <div className="manulist-onopen-block-box">
            <div>
              <div className="hamburdermenu-close" onClick={handelClick}>
                <div className="hamburdermenu-inner">
                  <i className={Click ? "fa  fa-times" : "fa  fa-bars"} />
                </div>
              </div>
            </div>
            <div className="menubar-right-side">
              <div>
                <p>
                  <span>
                    <b>Get In Touch</b>
                  </span>
                  <br />
                  Rajkot, Gujarat - 360002 <br /> India
                </p>
              </div>
              <div>
                <p>
                  <span>
                    <b>Work Inquiries</b>
                  </span>
                  <br />
                  info@recapinfotech.com
                </p>
              </div>
              <div className="socialmedia-in-menulist">
                <a
                  href="https://www.linkedin.com/company/recapinfotech/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src="/accets/images/linkdin.svg" alt="" />
                </a>
                <a
                  href="https://twitter.com/Recapinfotech"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src="/accets/images/twitter.svg" alt="" />
                </a>
                {/*                 <img src="/accets/images/instagram.svg" alt="" />
                 */}{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
}

export default Navbar;
