import React, { useEffect } from "react";
import { motion } from "framer-motion";
import { Link, useLocation, useParams } from "react-router-dom";
import Productfile from "./OurWorks";
function ProjectDetailes() {
  const container = {
    show: {
      transition: {
        staggerChildren: 0.1,
      },
    },
  };
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const { id } = useParams();
  const Project = Productfile.find((p) => p.DetailesId === id);
  /* console.log(id); */
  if (!Project) {
    return (
      <motion.div
        className="work-wrapper"
        initial="hidden"
        animate="show"
        variants={container}
      >
        <div className="lines"></div>
        <div className="work-wrapper-left">
          <motion.div
            className="how_head"
            whileInView="visible"
            viewport={{ once: true }}
            transition={{ duration: 0.5, delay: 0 }}
            variants={{
              visible: { opacity: 1 },
              hidden: { opacity: 0 },
            }}
          >
            <p>Our Project.</p>
          </motion.div>
          <h1>
            <motion.span
              whileInView="visible"
              viewport={{ once: true }}
              transition={{ duration: 0.5 }}
              variants={{
                visible: { y: 0, opacity: 1 },
                hidden: { y: 10, opacity: 0 },
              }}
            >
              Something went wrong Project is not found
            </motion.span>{" "}
          </h1>
          <Link to="/Work" className="exploreMorePro">
            Explore other projects
          </Link>
        </div>
      </motion.div>
    );
  }
  return (
    <motion.div
      className="ProjectDetailes-wrapper"
      style={{ backgroundColor: Project.backGround }}
      initial="hidden"
      animate="show"
      variants={container}
    >
      <div className="lines"></div>
      <div className="ProjectDetailes-wrapper-inner">
        <div className="ProjectDetailes-wrapper-right">
          {/*   <div className="projectimges-box">
            <motion.img
              src={Project.Img}
              alt={Project.name}
              whileInView="visible"
              viewport={{ once: true }}
              transition={{ duration: 0.5, delay: 0.1 }}
              variants={{
                visible: { y: 0, opacity: 1 },
                hidden: { y: 15, opacity: 0 },
              }}
            />
          </div> */}
          {Project.moreimage.map((image) => (
            <div className="projectimges-box ">
              <motion.img
                key={image}
                src={image}
                alt={Project.name}
                whileInView="visible"
                viewport={{ once: true }}
                transition={{ duration: 0.5, delay: 0.2 }}
                variants={{
                  visible: { y: 0, opacity: 1 },
                  hidden: { y: 15, opacity: 0 },
                }}
              />
            </div>
          ))}
        </div>
        <div className="ProjectDetailes-wrapper-left">
          <div className="ProjectDetailes-left-inner">
            <div className="ProjectDetailes-left-inner-container">
              <motion.div
                className="how_head"
                whileInView="visible"
                viewport={{ once: true }}
                transition={{ duration: 0.5, delay: 0.3 }}
                variants={{
                  visible: { opacity: 1 },
                  hidden: { opacity: 0 },
                }}
              >
                <p>{Project.type}.</p>
              </motion.div>
              <motion.h1
                whileInView="visible"
                viewport={{ once: true }}
                transition={{ duration: 0.5, delay: 0.4 }}
                variants={{
                  visible: { y: 0, opacity: 1 },
                  hidden: { y: 10, opacity: 0 },
                }}
                style={{ color: Project.backGroundDark }}
              >
                {Project.name}
              </motion.h1>
              <motion.p
                className="project-diss"
                whileInView="visible"
                viewport={{ once: true }}
                transition={{ duration: 0.5, delay: 0.5 }}
                variants={{
                  visible: { y: 0, opacity: 1 },
                  hidden: { y: 10, opacity: 0 },
                }}
              >
                {Project.description}
              </motion.p>
              <div className="more-detailes-projects">
                <ul>
                  <motion.li
                    className="more-detailes-projects-inner"
                    whileInView="visible"
                    viewport={{ once: true }}
                    transition={{ duration: 0.5, delay: 0.6 }}
                    variants={{
                      visible: { y: 0, opacity: 1 },
                      hidden: { y: 10, opacity: 0 },
                    }}
                  >
                    <h4 style={{ borderColor: Project.backGroundDark }}>
                      Task
                    </h4>
                    <p>{Project.task}</p>
                  </motion.li>
                  <motion.li
                    className="more-detailes-projects-inner"
                    whileInView="visible"
                    viewport={{ once: true }}
                    transition={{ duration: 0.5, delay: 0.7 }}
                    variants={{
                      visible: { y: 0, opacity: 1 },
                      hidden: { y: 10, opacity: 0 },
                    }}
                  >
                    <h4 style={{ borderColor: Project.backGroundDark }}>
                      Technology
                    </h4>
                    <p>{Project.Technology}</p>
                  </motion.li>
                </ul>
              </div>
              {Project.weburl == "" ? (
                ""
              ) : (
                <motion.a
                  className="project-link"
                  href={Project.weburl}
                  target="_blank"
                  whileInView="visible"
                  viewport={{ once: true }}
                  transition={{ duration: 0.5, delay: 0.8 }}
                  variants={{
                    visible: { y: 0, opacity: 1 },
                    hidden: { y: 10, opacity: 0 },
                  }}
                >
                  Open Project &nbsp;{" "}
                  <i class="fa fa-long-arrow-right" aria-hidden="true"></i>
                </motion.a>
              )}
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
}

export default ProjectDetailes;
