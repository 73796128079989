import React, { useEffect, useRef } from "react";
import { motion } from "framer-motion";
import Footer from "../components/footer";
import { useLocation } from "react-router-dom";
import emailjs from "@emailjs/browser";
function ContactUs() {
  const container = {
    show: {
      transition: {
        staggerChildren: 0.1,
      },
    },
  };
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const form = useRef();
  const sendEmail = (e) => {
    if (
      document.getElementById("funame").value !== "" &&
      document.getElementById("email").value !== "" &&
      document.getElementById("Services").value !== "" &&
      document.getElementById("Cname").value !== "" &&
      document.getElementById("massage").value !== ""
    ) {
      e.preventDefault();
      document.getElementById("contactloddingofbutton").style.display = "block";
      document.getElementById("sendContact").style.display = "none";

      {
        {
          /* this email is setup on recapinfotech@gmail.com on https://dashboard.emailjs.com/*/
        }
      }
      emailjs
        .sendForm(
          "service_b0yl538",
          "template_1bu4vmw",
          form.current,
          "2ViIgP_7IluYuMMJy"
        )
        .then(
          (result) => {
            /* console.log(result.text); */
            document.getElementById("funame").value = "";
            document.getElementById("email").value = "";
            document.getElementById("Services").value = "";
            document.getElementById("Cname").value = "";
            document.getElementById("massage").value = "";
            document.getElementById("contactunsuccessmsg").style.display =
              "none";
            document.getElementById("contactemptyfuild").style.display = "none";
            document.getElementById("Contacttsuceesmsg").style.display =
              "block";
            document.getElementById("contactloddingofbutton").style.display =
              "none";
            document.getElementById("sendContact").style.display = "block";
          },
          (error) => {
            console.log(error.text);
            document.getElementById("Contacttsuceesmsg").style.display = "none";
            document.getElementById("contactemptyfuild").style.display = "none";
            document.getElementById("contactunsuccessmsg").style.display =
              "block";
            document.getElementById("contactloddingofbutton").style.display =
              "none";
            document.getElementById("sendContact").style.display = "block";
          }
        );
    } else {
      e.preventDefault();
      document.getElementById("Contacttsuceesmsg").style.display = "none";
      document.getElementById("contactunsuccessmsg").style.display = "none";
      document.getElementById("contactemptyfuild").style.display = "block";
      return false;
    }
  };
  return (
    <motion.div
      variants={container}
      className="contactUs-mai-wrapper"
      initial="hidden"
      animate="show"
    >
      <div className="lines"></div>
      <div className="contactUs-inner">
        <motion.div
          className="how_head"
          whileInView="visible"
          viewport={{ once: true }}
          transition={{ duration: 1, delay: 0 }}
          variants={{
            visible: { opacity: 1 },
            hidden: { opacity: 0 },
          }}
        >
          <p>Contact With Us.</p>
        </motion.div>
        <div className="headding-contactUs-inners">
          <h1>
            <motion.span
              whileInView="visible"
              viewport={{ once: true }}
              transition={{ duration: 0.5 }}
              variants={{
                visible: { y: 0, opacity: 1 },
                hidden: { y: 10, opacity: 0 },
              }}
            >
              Let's
            </motion.span>
            <span>&nbsp;</span>
            <motion.span
              whileInView="visible"
              viewport={{ once: true }}
              transition={{ duration: 0.5, delay: 0.07 }}
              variants={{
                visible: { y: 0, opacity: 1 },
                hidden: { y: 10, opacity: 0 },
              }}
            >
              meet
            </motion.span>
            <span>&nbsp;</span>
            <motion.span
              whileInView="visible"
              viewport={{ once: true }}
              transition={{ duration: 0.5, delay: 0.14 }}
              variants={{
                visible: { y: 0, opacity: 1 },
                hidden: { y: 10, opacity: 0 },
              }}
            >
              up
            </motion.span>
            <span>&nbsp;</span>
            <motion.span
              whileInView="visible"
              viewport={{ once: true }}
              transition={{ duration: 0.5, delay: 0.21 }}
              variants={{
                visible: { y: 0, opacity: 1 },
                hidden: { y: 10, opacity: 0 },
              }}
            >
              over a
            </motion.span>
            <span>&nbsp;</span>
            <motion.span
              whileInView="visible"
              viewport={{ once: true }}
              transition={{ duration: 0.5, delay: 0.28 }}
              variants={{
                visible: { y: 0, opacity: 1 },
                hidden: { y: 10, opacity: 0 },
              }}
            >
              call
            </motion.span>
            <span>&nbsp;</span>
            <motion.span
              whileInView="visible"
              viewport={{ once: true }}
              transition={{ duration: 0.5, delay: 0.35 }}
              variants={{
                visible: { y: 0, opacity: 1 },
                hidden: { y: 10, opacity: 0 },
              }}
            >
              and
            </motion.span>
            <span>&nbsp;</span>
            <motion.span
              whileInView="visible"
              viewport={{ once: true }}
              transition={{ duration: 0.5, delay: 0.42 }}
              variants={{
                visible: { y: 0, opacity: 1 },
                hidden: { y: 10, opacity: 0 },
              }}
            >
              discuss
            </motion.span>
            <span>&nbsp;</span>
            <motion.span
              whileInView="visible"
              viewport={{ once: true }}
              transition={{ duration: 0.5, delay: 0.49 }}
              variants={{
                visible: { y: 0, opacity: 1 },
                hidden: { y: 10, opacity: 0 },
              }}
            >
              your
            </motion.span>
            <span>&nbsp;</span>
            <motion.span
              whileInView="visible"
              viewport={{ once: true }}
              transition={{ duration: 0.5, delay: 0.56 }}
              variants={{
                visible: { y: 0, opacity: 1 },
                hidden: { y: 10, opacity: 0 },
              }}
            >
              needs
            </motion.span>
            <span>&nbsp;</span>
            <motion.span
              whileInView="visible"
              viewport={{ once: true }}
              transition={{ duration: 0.5, delay: 0.63 }}
              variants={{
                visible: { y: 0, opacity: 1 },
                hidden: { y: 10, opacity: 0 },
              }}
            >
              and
            </motion.span>
            <span>&nbsp;</span>
            <motion.span
              whileInView="visible"
              viewport={{ once: true }}
              transition={{ duration: 0.5, delay: 0.7 }}
              variants={{
                visible: { y: 0, opacity: 1 },
                hidden: { y: 10, opacity: 0 },
              }}
            >
              potential
            </motion.span>
            <span>&nbsp;</span>
            <motion.span
              whileInView="visible"
              viewport={{ once: true }}
              transition={{ duration: 0.5, delay: 0.77 }}
              variants={{
                visible: { y: 0, opacity: 1 },
                hidden: { y: 10, opacity: 0 },
              }}
            >
              work
            </motion.span>
            <span>&nbsp;</span>
            <motion.span
              whileInView="visible"
              viewport={{ once: true }}
              transition={{ duration: 0.5, delay: 0.84 }}
              variants={{
                visible: { y: 0, opacity: 1 },
                hidden: { y: 10, opacity: 0 },
              }}
            >
              collaboration.
            </motion.span>
          </h1>
        </div>
        <div className="contactUs-form-wrapper">
          <form ref={form} onSubmit={sendEmail}>
            <div className="form-box-contcatus col">
              <motion.div
                whileInView="visible"
                viewport={{ once: true }}
                transition={{ duration: 1, delay: 0.88 }}
                variants={{
                  visible: { y: 0, opacity: 1 },
                  hidden: { y: 10, opacity: 0 },
                }}
              >
                <label>Your Name</label>
                <input
                  type="text"
                  name="funame"
                  id="funame"
                  placeholder="Fullname"
                />
              </motion.div>
              <motion.div
                whileInView="visible"
                viewport={{ once: true }}
                transition={{ duration: 1, delay: 0.92 }}
                variants={{
                  visible: { y: 0, opacity: 1 },
                  hidden: { y: 10, opacity: 0 },
                }}
              >
                <label>Your Email</label>
                <input
                  type="text"
                  id="email"
                  name="email"
                  placeholder="Your Email Address"
                />
              </motion.div>
            </div>
            <div className="form-box-contcatus col">
              <motion.div
                whileInView="visible"
                viewport={{ once: true }}
                transition={{ duration: 1, delay: 0.96 }}
                variants={{
                  visible: { y: 0, opacity: 1 },
                  hidden: { y: 10, opacity: 0 },
                }}
              >
                <label>What services you are interested in?</label>
                <input
                  type="text"
                  id="Services"
                  name="Services"
                  placeholder="Services"
                />
              </motion.div>
              <motion.div
                whileInView="visible"
                viewport={{ once: true }}
                transition={{ duration: 1, delay: 1 }}
                variants={{
                  visible: { y: 0, opacity: 1 },
                  hidden: { y: 10, opacity: 0 },
                }}
              >
                <label>Your company name</label>
                <input
                  type="text"
                  id="Cname"
                  name="Cname"
                  placeholder=" Company name"
                />
              </motion.div>
            </div>
            <motion.div
              className="form-box-contcatus"
              whileInView="visible"
              viewport={{ once: true }}
              transition={{ duration: 1, delay: 1.04 }}
              variants={{
                visible: { y: 0, opacity: 1 },
                hidden: { y: 10, opacity: 0 },
              }}
            >
              <label>Please tell us about your and your project...</label>
              <textarea
                placeholder="Please tell"
                id="massage"
                name="massage"
                rows="10"
              ></textarea>
            </motion.div>
            <motion.div
              className="form-box-contcatus"
              whileInView="visible"
              viewport={{ once: true }}
              transition={{ duration: 1 }}
              variants={{
                visible: { y: 0, opacity: 1 },
                hidden: { y: 10, opacity: 0 },
              }}
            >
              <button className="btn btn-allrecap" type="submit">
                <span className="sendContact" id="sendContact">
                  Send
                </span>
                <i
                  className="fa fa-spinner contactloddingofbutton"
                  id="contactloddingofbutton"
                ></i>
              </button>
              <div style={{ width: "100%" }}>
                <p className="Contacttsuceesmsg" id="Contacttsuceesmsg">
                  Your Message is sent Successfully
                </p>
                <p className="contactunsuccessmsg" id="contactunsuccessmsg">
                  Something went wrong, your message isn't sent. Try again!!!
                </p>
                <p className="contactunsuccessmsg" id="contactemptyfuild">
                  You have empty fields, fill them first.
                </p>
              </div>
            </motion.div>
          </form>
        </div>
      </div>
      <Footer />
    </motion.div>
  );
}

export default ContactUs;
